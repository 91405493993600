<template>
    <div class="phoneInput" style="text-align: left;">
        <van-nav-bar title="彩速达" />
        <br /><br /><br /><br />
        <div style="padding-left: 35px;padding-right: 35px;">
            <div class="logo"><img :src="logoPngPath" alt="彩速达" /></div>
            <div class="welcome">
                <span style="font-size: 25px;">你好，</span><br />
                <span style="font-size: 25px;">欢迎来到彩速达！</span>
            </div>
            <div class="clear"></div>
            <br /><br /><br />
            <!-- 输入手机号，调起手机号键盘 -->
            <van-field v-model="tel" clearable type="tel" label="账号" placeholder="请输入本人手机号" @input="inputChange" />
            <van-field v-model="password" type="password" label="密码" placeholder="请输入密码" maxlength='18' />
            <!-- <span style="font-size: 13px;color: #c1bebe;">未注册的手机号验证后自动创建账号</span> -->
            <br /><br /><br />
            <van-button type="info" :disabled="btnDisabled" block @click="onSubmit">登录</van-button>
            <van-button type="text" class="register" @click="onRegister">注册</van-button>
        </div>
        <div class="bah-box">
            <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022007240号-2</a>
            <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">苏公网安备32010202011874号</a>
        </div>
    </div>
</template>

<script>
    import { userLogin } from "@/api/login";
    import logoPng from "@/assets/logo.png";
    import MD5 from "md5.js";
    import { Notify } from "vant";

    export default {
        name: "PhoneInput",
        components: {},
        data() {
            return {
                tel: "",
                password: "",
                btnDisabled: true,
                logoPngPath: logoPng
            };
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.tel = this.$route.query.phone;
                this.inputChange(this.tel);
            },
            inputChange(val) {
                // console.log(val)
                if (val) {
                    if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(val)) {
                        this.btnDisabled = false;
                    } else {
                        this.btnDisabled = true;
                    }
                } else {
                    this.btnDisabled = true;
                }
            },
            onSubmit() {
                console.log(this.tel);
                if (!this.password) {
                    Notify("请输入密码");
                    return false;
                }
                if (this.password.length < 6 || this.password.length > 18) {
                    Notify("请输入6到18位的密码");
                    return false;
                }
                let params = {
                    phone: this.tel,
                    password: this.md5Encryption(this.password)
                };
                // 验证账号密码登录
                userLogin(params).then(res => {
                    console.log(res);
                    const code = res["code"];
                    const retData = res.data;
                    if (code == 0) {
                        // 验证成功
                        // 根据角色类型进行处理
                        const role = retData["role"];
                        // 设置登录token
                        localStorage.setItem("token", retData["token"]);
                        // 同时设置保存角色
                        localStorage.setItem("role", role);
                        // 区域经理
                        if (role == "region_manager") {
                            // 直接进入首页（区域经理）
                            this.$router.push({ path: "/" });
                        } else if (role == "terminator") {
                            // 终端用户
                            // 用户状态
                            const status = retData["status"];
                            // 临时账号 或者 待审核中的账号 去到 指定区域经理页面
                            // 2.1 首次注册或者临时账号状态 进入 指定区域经理页面
                            if (status == -9) {
                                let queryParams = {
                                    phone: params.phone,
                                    status: status
                                };
                                this.$router.push({
                                    path: "/setRegionManager",
                                    query: queryParams
                                });
                            } else if (status < 0) {
                                let queryParams = {
                                    phone: params.phone,
                                    status: status
                                };
                                this.$router.push({
                                    path: "/checkPending",
                                    query: queryParams
                                });
                            } else if (status == 1) {
                                // 直接进入首页（终端）
                                this.$router.push({ path: "/" });
                            }
                        }
                    }
                });
                //   doPhoneCheck(params).then(response => {
                //     const retData = response.data
                //     console.log(retData);
                //     // TODO
                //     //if(retData == 99 || retData == 1) {
                //       this.$router.push({path: '/validCodeInput',query: params});

                //     //}
                //   });
            },
            onRegister() {
                this.$router.push({ path: "/validCodeInput" });
            },
            md5Encryption(val) {
                return new MD5().update(val).digest("hex");
            }
        }
    };
</script>
<style lang="scss" scoped>
    .logo {
        width: 90px;
        height: 90px;
        float: left;
        margin-right: 15px;
    }
    .logo img {
        width: 100%;
        height: 100%;
    }
    .welcome {
        width: calc(100% - 116px);
        height: 60px;
        margin: 15px 0;
        float: left;
        border-left: 1px solid #e7e4e4;
        padding-left: 10px;
    }
    .clear {
        clear: both;
    }
    .register {
        border: 0;
        background: none;
        color: var(--van-button-primary-background-color);
        float: right;
    }
    .bah-box {
        position: fixed;
        left: 0px;
        right: 0px;
        bottom: 15px;
        a {
            font-size: 15px;
            color: #333;
            padding-bottom: 6px;
            display: block;
            text-align: center;
        }
    }
</style>
